import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import Obfuscate from 'react-obfuscate';
export const _frontmatter = {
  "title": "Contact Us"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout className="page contact-page" mdxType="Layout">
      <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
      <div className="col-10 offset-1">
        <h1>{`Contact Us`}</h1>
        <p className="text-center mt-5 font-italic fs-3">For any enquiries,<br />send an email</p>
        <div className="container text-center">
          <Obfuscate className="btn btn-success btn-lg mx-auto d-inline" email="contact@huey.co" headers={{
            //         cc: 'dade@zero-cool.af',
            //         bcc: 'smith@machina.net',
            subject: 'Hello Huey',
            body: ''
          }} mdxType="Obfuscate" />
        </div>
        <div className="container d-flex justify-content-center">
          <p className="text-left font-weight-bold fs-4" style={{
            "marginTop": "100px",
            "paddingLeft": "60px"
          }}>
HUEY.CO PTY LTD<br />
Suite 109 Atwell Buildings<br />
3 Cantoment Street<br />
Fremantle WA 6160<br />
            <br />
ACN: 640 035 436<br />
ABN: 94 640 035 436<br />
          </p>
        </div>
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      